.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: auto;
  margin-bottom: 20px;

  .warning-thankyou-message {
    color: rgb(211, 47, 47);
    padding: 50px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0,0,0,.1);
    text-align: center;
    width: 90%;
    text-transform: none;
    font-weight: 500;
    font-size: 1.8em;
    line-height: 140.62%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-feature-settings: "c2sc";
    font-variant: small-caps;
  }
}

.notfound-main {
  margin-top:220px;
}