@import '../styles';

.rules-questions-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 30px auto 50px auto;
  width: 70%;

  @include sm {
    margin: 30px auto;
    width: 90%;
    font-size: 1rem;
  }

  button {
    @include sm {
      font-size: 16px;
    }
  }

  h1 {
    color: $orange;
    margin-right: 30px;
    font-weight: 600;
    font-size: 1.5rem;

    @include sm {
      font-weight: 500;
    }
  }

  h2 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    font-weight: 500;

    @include sm {
      text-align: center;
    }
  }

  .rules-questions {
    display: flex;
    width: 100%;
    padding: 20px;
    margin: 5px 0;
    background: $white;
    box-shadow: $display-shadow;
    border-radius: 5px;

    @include sm {
      flex-direction: row;
      align-items: center;
    }
  }

  p {
    font-size: 20px;

    @include sm {
      font-size: 17px;
    }
  }

  button {
    @extend %main-button;

    @include sm {
      width: 200px;
    }
  }
}

.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  margin-bottom: 20px;

  @include sm {
    margin: 30px auto;
    width: 90%;
  }

  .info-message {
    padding: 10px;
    background: darkseagreen;
    color: white;
    border-radius: 7px;
  }

  .warning-message {
    padding: 50px;
    border-radius: 5px;
    color: $red;
    background: $white;
    box-shadow: $display-shadow;
    text-align: center;
    width: 90%;
    text-transform: none;

    @include sm {
      padding: 30px;
    }
  }

  .orangepass-message {
    padding: 50px;
    border-radius: 5px;
    color: $orange;
    background: $white;
    box-shadow: $display-shadow;
    text-align: center;
    width: 90%;
    text-transform: none;

    @include sm {
      padding: 30px;
    }
  }

  h1 {
    margin-top: 50px;
    color: $orange;
  }

  p {
    margin-top: 10px;
  }

  a {
    color: $orange;
  }

  button {
    @extend %main-button;
    margin-top: 100px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;

  @include sm {
    margin: 30px;
  }
}

.modal-content {
  margin-bottom: 20px;

  h3 {
    margin-bottom: 20px;
    font-size: 40px;

    @include sm {
      font-size: 30px;
    }
  }
}

.modal-buttons {
  display: flex;
  justify-content: end;
  width: 100%;
  // gap: 20px;

  @include sm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  .modal-reject {
    background-color: gray;
    color: $white;
  }

  .modal-accept {
    background-color: $red;
    color: $white;
  }
}
