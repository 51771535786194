@import '../styles';

.reject-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: $orange;
    margin-bottom: 1rem;
    text-transform: capitalize;
  }
}
