@import '../styles';

.qr-container {
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 100px;

  .entry-option-main {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .entry-option-welcome {
      font-size: 1.3rem;
      font-weight: 400;
      line-height: 140.62%;

      h2 {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 140.62%;
      }
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 140.62%;
  }

  .entry-header-title {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 140.62%;
    color: $orange;
  }

  .entry-header-desc {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 140.62%;
    color: #000;
  }

  .entry-option {
    margin: 20px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: $display-shadow;
    background-color: $white;
    height: 60vh;
    width: 400px;
  }

  h2 {
    margin: 0 0 20px 0;
  }

  span {
    color: $orange;
    font-size: 30px;
  }

  button {
    @extend %main-button;
    margin: 30px 0;
  }

  @include md {
    display: grid;
    justify-content: center;
    text-align: center;
    margin: 50px auto;
  }
}

.qr-container .entry-option-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin-top: 10px;
  align-items: center;
  // flex-wrap: wrap;

  .footer-description {
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 140.62%;
    // margin-left: -78px;
  }
}
