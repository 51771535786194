@import '../styles.scss';

.settings-container {
  margin: 0 auto;

  .settings-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-weight: 400;
      font-size: 24px;
    }

    .settings-btn-csv {
      display: block;
      width: 200px;
      height: 60px;
      border-radius: 5px;
      font-size: 20px;
      margin: 0 0 10px 0;
      line-height: 60px;
      text-decoration: none;
      color: $white;
      border: 1px solid $orange;
      outline: 1px solid $orange;
      background-color: $orange;
      box-shadow: $button-shadow;
      text-align: center;

      &:hover {
        color: $orange;
        border: 1px solid $orange;
        background-color: $white;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0 1rem;
    border-radius: 5px;
  }

  table,
  th,
  td {
    border: none;
    font-weight: 400;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
    width: fit-content;
  }

  th {
    font-weight: 500;
    font-size: 20px;
  }

  td {
    font-size: 18px;
  }

  ul {
    margin-top: 20px;
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 5px;
  }

  thead tr{
      color: $grey;
  }

  .user-row {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
    border: 1px solid #ccc;
    padding: 20px;

    td:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    td:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  button {
    color: #fff;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid $orange;
    background-color: $orange;
    box-shadow: $button-shadow;
    text-align: center;

    &:hover {
      color: $orange;
      border: 1px solid $orange;
      background-color: $white;
    }
  }

  .error-message {
    margin-top: 10px;
    color: red;
  }

  .info-message {
    padding: 10px;
    background: darkseagreen;
    color: white;
    border-radius: 7px;
  }

  .user-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }

  .user-button {
    color: #fff;
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
  }

  .delete-button {
    background-color: #dc3545;
    border: 1px solid #dc3545;
  }

  p {
    color: red;
    font-weight: bold;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
  }
}
