@import '../styles';

// .scrollingContent {
//   height: calc(100% - 60px - 186px) !important;
//   overflow: auto !important;
//   -webkit-overflow-scrolling: touch !important;
// }

:root {
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0, 0, 0, 0.2);

  --scrollbar-size: 0.775rem;
  --scrollbar-minlength: 1.5rem;
}
// .overflowing-element::-webkit-scrollbar {
//   height: var(--scrollbar-size);
//   width: var(--scrollbar-size);
// }
// .overflowing-element::-webkit-scrollbar-track {
//   background-color: var(--scrollbar-track-color);
// }
// .overflowing-element::-webkit-scrollbar-thumb {
//   background-color: var(--scrollbar-color);
// }
// .overflowing-element::-webkit-scrollbar-thumb:vertical {
//   min-height: var(--scrollbar-minlength);
// }
// .overflowing-element::-webkit-scrollbar-thumb:horizontal {
//   min-width: var(--scrollbar-minlength);
// }

.waiver-main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;
  user-select: none;
  min-height: 100%;
  height: 100%;
  position: relative;
  touch-action: auto !important;

  .back-button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 15px;
    width: 100%;
    padding: 0 20px;
    height: 45px;

    .back-button {
      width: 150px !important;
      height: 36px !important;
      background: $white;
      border-radius: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 140.62%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $orange;
      cursor: pointer;
    }
  }

  .rule-container {
    margin-bottom: 5px 0px 10px 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 64%;

    @include sm {
      width: 90%;
    }

    .questions-title-wavier {
      font-size: 1.3rem;
      font-weight: 400;
    }

    .rules-questions-container {
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: auto;
      margin-bottom: 20px;
      width: 100%;

      h1 {
        color: $orange;
        margin-right: 30px;
      }

      h2 {
        margin-bottom: 20px;
      }

      .rules-data {
        margin-top: 10px;
        width: 100%;
      }

      .rules-questions {
        display: flex;
        width: 100%;
        padding: 20px;
        margin: 5px 0;
        background: $white;
        box-shadow: $display-shadow;
        border-radius: 5px;
        align-items: center;
      }

      .rule-question-content {
        font-style: normal;
        font-weight: normal;
        font-size: 0.9em;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        color: $black;
        word-break: break-all;
      }

      .not-accept-text {
        font-size: 0.8em;
        color: $grey;
        margin-top: 15px;
        cursor: pointer;
      }

      .cursor-pointer {
        cursor: pointer !important;
      }

      .full-width {
        width: 100% !important;
      }

      p {
        font-size: 0.9em;
        line-height: 140.62%;
        word-break: break-all;
      }

      button {
        @extend %main-button;

        @include sm {
          width: 200px;
        }
      }
    }

    .message-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 600px;
      height: auto;
      margin-bottom: 20px;

      @include sm {
        margin: 30px auto;
        width: 90%;
      }

      .warning-message {
        padding: 50px;
        border-radius: 5px;
        color: $red;
        background: $white;
        box-shadow: $display-shadow;
        text-align: center;
        width: 90%;
        text-transform: none;

        @include sm {
          padding: 30px;
        }
      }

      .warning-thankyou-message {
        font-weight: 500;
        font-size: 1.8em;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-variant: small-caps;
        color: $orange;
      }

      .terms-condition-label {
        margin-top: 20px;
        font-weight: normal;
        font-size: 0.9em;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        color: $black;
      }

      .click-here-text {
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
      }

      .question-message-container {
        margin-top: 170px !important;
      }

      .orangepass-message {
        border-radius: 5px;
        color: $orange;
        background: $white;
        text-align: center;
        text-transform: none;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8em;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        font-variant: small-caps;
        padding: 10px 20px;
      }

      h1 {
        margin-top: 50px;
        color: $orange;
      }

      p {
        margin-top: 10px;
      }

      a {
        color: $orange;
      }

      button {
        @extend %main-button;
        margin-top: 100px;
      }
    }

    .character-count-label {
      width: 90%;
      color: #b5adad;
      font-size: 0.8em;
      display: flex;
      justify-content: flex-end;
    }

    .user-wavier-container {
      background: $white;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      // height: 600px; /* Set a fixed height */
      width: 100%;
      padding: 10px;
      // height: calc(100% - (45px  186px));
      height: 470px;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      .scrollingContent {
        // height: calc(100% - 60px - 186px) !important;
        overflow: auto !important;
        -webkit-overflow-scrolling: touch !important;
      }
    }
    .user-wavier-container > * {
      -webkit-transform: translateZ(0px);
    }
    .user-wavier-container > * {
      -webkit-transform: translateZ(0px);
    }

    .rules-questions-wavier {
      display: flex;
      width: 100%;
      padding: 20px;
      margin: 5px 0;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 400;

      .index-no {
        font-style: normal;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        color: $orange;
        margin-right: 25px;
      }

      .inside-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .accept-rules-questions {
          color: $orange;
        }
      }
    }

    .accept-rules-question {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 140.62%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $orange;
      margin-right: 30px;
    }

    .questions-title-wavier {
      font-size: 1.5rem;
      font-weight: normal;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start !important;
      width: 100%;
    }
    .questions-rules-title {
      font-size: 1.5rem;
      font-weight: 400;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 140.62%;
    }

    .residents-wavier-container {
      font-weight: normal;
      font-size: 18px;
      line-height: 140.62%;
      color: $black;
      margin: 20px;
    }

    .residents-wavier {
      margin: 10px;
      display: flex;
      align-items: center;
    }

    .residents-key {
      text-transform: capitalize;
      margin-right: 10px;
    }

    .thankyou-message-wavier {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .footer-content {
    width: 100%;
    height: 186px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    background: $white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: fixed;
    bottom: 0;
    left: 0;

    .footer-content-box {
      display: flex;
      text-align: center;
    }

    .wavier-footer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      .wavier-submit {
        width: 300px;
        height: 60px;
        background: $orange;
        border-radius: 5px;
        border: 1px solid $orange;
        font-weight: 500;
        font-style: $font;
        font-size: 1.3rem;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        cursor: pointer;

        &:hover {
          color: $orange;
          background: $white;
        }
      }

      .website-link {
        margin-top: 0.4rem;
        font-size: 1.2rem;
      }
    }

    .wavier-lock-icon {
      margin-right: 20px;
    }
  }
}
