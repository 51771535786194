@import '../styles';

.form {
  max-width: 300px;
  margin-bottom: 30px;

  h2 {
    margin: 20px 0;
    font-size: 24px;
    font-weight: 400;
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    button {
      margin: 10px;
      height: 40px;
    }
  }

  .editlocation-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;

    button {
      margin: 10px;
      height: 40px;
    }
  }

  button {
    @extend %main-button;
  }

  .error-message {
    margin-top: 10px;
    color: $red;
  }

  .info-message {
    padding: 10px;
    background: darkseagreen;
    color: white;
    border-radius: 7px;
  }

  .warning-message {
    margin-top: 10px;
    color: $orange;
  }

  a {
    color: $orange;
    font-size: 14px;
  }

  .phone-number::-webkit-inner-spin-button,
  .phone-number::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-wrapper {
  display: grid;
  border: 1px;
  padding: 5px 0;

  input {
    padding: 10px 10px 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    height: 60px;
    border: 1px solid $grey;
    background-color: $white;
    color: $black;
    font-family: $font;

    &:focus {
      outline: none;
      border: 1px solid $orange;
    }
  }
}

.file-label {
  padding-left: 20px;
  font-size: 16px;
  line-height: 60px;
  background-color: $white;
  color: $grey;
  height: 60px;
  border-radius: 5px;
  border: 1px solid $grey;
  font-family: $font;

  &:hover {
    cursor: pointer;
    border: 1px solid $orange;
  }
}

input[type='file'] {
  display: none;
}

.edit-location-inputs-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-top: 1rem;
  gap: 1rem;
  border: 1px solid $grey;
  border-radius: 5px;
  padding: 1rem;
  box-shadow: $display-shadow;
  background-color: $white;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    gap: 1rem;
  }
}
