@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap');

$font: 'Montserrat', sans-serif;

// colors
$background: #f4f4f4;
$blue: #2984f6;
$red: #d32f2f;
$green: #28b063;
$grey: #a5a5a5;
$orange: #f66d51;
$black: #000000;
$white: #ffffff;

// shadow
$button-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
$display-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
