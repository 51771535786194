@import '../styles';

.thank-you-container {
  //content should be centered vertically and horizontally
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #f66d51!important;


    h1 {
        font-size: 2em;
        line-height: 92%;
        text-transform: uppercase;
    }

    .title {
        padding: 15px;
        background: white;
        margin-bottom: 16px;
    }
}